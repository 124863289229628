import React from 'react';
import {Suspense, lazy} from 'react';
import {Navigate} from 'react-router-dom';
import Index from "./pages";
import Info from "./pages/info";
// import { RouteObject } from 'react-router';



const routes = () => [
    {
        path: '',

        children: [
            {
                path: '/',
                element: <Index/>
            },

            // {
            //     path: 'status',
            //     children: [
            //         {
            //             path:  '',
            //             element: <Navigate to="404" replace/>
            //         },
            //         {
            //             path: '404',
            //             element: <NotFound/>
            //         },
            //         {
            //             path: '500',
            //             element: <NotFound/>
            //         },
            //
            //     ]
            // },
            // {
            //     path: '*',
            //     element: <NotFound/>
            // },
            // {
            //     path:'products',
            //     children: [
            //         {
            //             path:  'management',
            //             element: <Productlist/>
            //         },
            //
            //     ]
            // },
            {
                path: '/:chainid/:key/:addr/:data',
                element: <Info/>,
            },
            // {
            //     path: 'logout',
            //     element: isLoggedIn ?  :<Navigate to="/login"/>   ,
            // },

        ]
    },

];

export default routes;
