import logo from './logo.svg';
import './App.css';
import router from './Router';
import {useRoutes} from 'react-router-dom';

import './styles/css/bootstrap.min.css'
import './styles/css/flaticon.css'
import './styles/css/remixicon.css'
import 'react-tabs/style/react-tabs.css'
// import "../node_modules/aos/dist/aos.css"
// import 'react-accessible-accordion/dist/fancy-example.css';
import "swiper/css"
import "swiper/css/bundle"

// Global Styles
import './styles/css/style.css'
import './styles/css/responsive.css'

function App() {

    const content = useRoutes(router());
    return (
        <div>
            {content}
        </div>
    );

}

export default App;
