import * as React from "react"
import {Link} from 'react-router-dom';

import firebase from 'firebase';

import {useEffect, useState} from "react";

// import logo from "../../images/logo/logo.png"
// import footer1 from "../../images/footer/footer-1.jpg"
// import footer2 from "../../images/footer/footer-2.jpg"

// Import the functions you need from the SDKs you need

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const Footer = () => {
    // let numberOfVisits = localStorage.getItem("numberOfVisits");
    //
    // if (!numberOfVisits) {
    //     numberOfVisits = 0;
    // }
    // numberOfVisits = parseFloat(numberOfVisits) + 1;

    // localStorage.setItem("numberOfVisits", numberOfVisits);
    const firebaseConfig = {
        apiKey: "AIzaSyCQNizKNRZgTZ7lFB8IO-7mQbxqMFzE3is",
        authDomain: "roomap-9c7bc.firebaseapp.com",
        databaseURL: "https://roomap-9c7bc-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "roomap-9c7bc",
        storageBucket: "roomap-9c7bc.appspot.com",
        messagingSenderId: "913080163734",
        appId: "1:913080163734:web:b503a0979f6e793df3d1b7",
        measurementId: "G-D5M7E1HSF9",

    };
    try {
        firebase.initializeApp(firebaseConfig);
    } catch (err) {

        if (!/already exists/.test(err.message)) {
            console.error('Firebase initialization error', err.stack)
        }
    }


    const [visitor, setVisitor] = useState();


    const db = firebase.database();
    useEffect(()=>{
        if(!visitor)
        {
            const push = async () => {
                const value = (await db.ref("visit").get()).val();
                // console.log(value)

                setVisitor(parseInt(value)+1);
                db.ref("visit").set(+value + 1).catch(alert);
            }
            push();
        }
    },[visitor])


    // console.log('DATA RETRIEVED');


    return (
        <>
            {/*<div className="footer-area bg-color-f8f8f8 pt-100 pb-70">*/}
            {/*  <div className="container">*/}
            {/*    <div className="row">*/}
            {/*      <div className="col-lg-12 col-sm-12">*/}
            {/*        <div className="logo-area">*/}
            {/*          <Link to="/">*/}
            {/*            <img src={logo} className="logo-1" alt="Image" />*/}
            {/*          </Link>*/}
            {/*          <p>*/}
            {/*            Under Development with Cyrnetics Labs, Colonel Technology*/}
            {/*          </p>*/}

            {/*          <div className="social-links">*/}
            {/*            <ul>*/}
            {/*              <li>*/}
            {/*                <a*/}
            {/*                  href="https://www.facebook.com/promsurinp"*/}
            {/*                  target="_blank"*/}
            {/*                  rel="noreferrer"*/}
            {/*                >*/}
            {/*                  <i className="flaticon-facebook-1"></i>*/}
            {/*                </a>*/}
            {/*              </li>*/}
            {/*              /!*<li>*!/*/}
            {/*              /!*  <a*!/*/}
            {/*              /!*    href="https://www.twitter.com"*!/*/}
            {/*              /!*    target="_blank"*!/*/}
            {/*              /!*    rel="noreferrer"*!/*/}
            {/*              /!*  >*!/*/}
            {/*              /!*    <i className="flaticon-twitter-3"></i>*!/*/}
            {/*              /!*  </a>*!/*/}
            {/*              /!*</li>*!/*/}
            {/*              /!*<li>*!/*/}
            {/*              /!*  <a*!/*/}
            {/*              /!*    href="https://google.com"*!/*/}
            {/*              /!*    target="_blank"*!/*/}
            {/*              /!*    rel="noreferrer"*!/*/}
            {/*              /!*  >*!/*/}
            {/*              /!*    <i className="flaticon-google-plus"></i>*!/*/}
            {/*              /!*  </a>*!/*/}
            {/*              /!*</li>*!/*/}
            {/*              <li>*/}
            {/*                <a*/}
            {/*                  href="https://www.linkedin.com/in/promsurinp/"*/}
            {/*                  target="_blank"*/}
            {/*                  rel="noreferrer"*/}
            {/*                >*/}
            {/*                  <i className="flaticon-linkedin-1"/>*/}
            {/*                </a>*/}
            {/*              </li>*/}
            {/*            </ul>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}

            {/*      /!*<div className="col-lg-3 col-sm-6">*!/*/}
            {/*      /!*  <div className="footer-widjet pl-30">*!/*/}
            {/*      /!*    <h3>Platform</h3>*!/*/}
            {/*      /!*    <div className="link-list">*!/*/}
            {/*      /!*      <ul>*!/*/}
            {/*      /!*        <li>*!/*/}
            {/*      /!*          <Link to="#">*!/*/}
            {/*      /!*            <i className="flaticon-next"></i> About Us*!/*/}
            {/*      /!*          </Link>*!/*/}
            {/*      /!*        </li>*!/*/}
            {/*      /!*        /!*<li>*!/*!/*/}
            {/*      /!*        /!*  <Link to="/blog">*!/*!/*/}
            {/*      /!*        /!*    <i className="flaticon-next"></i> Blog*!/*!/*/}
            {/*      /!*        /!*  </Link>*!/*!/*/}
            {/*      /!*        /!*</li>*!/*!/*/}
            {/*      /!*        /!*<li>*!/*!/*/}
            {/*      /!*        /!*  <Link to="/team">*!/*!/*/}
            {/*      /!*        /!*    <i className="flaticon-next"></i> Our Team*!/*!/*/}
            {/*      /!*        /!*  </Link>*!/*!/*/}
            {/*      /!*        /!*</li>*!/*!/*/}
            {/*      /!*        /!*<li>*!/*!/*/}
            {/*      /!*        /!*  <Link to="/services">*!/*!/*/}
            {/*      /!*        /!*    <i className="flaticon-next"></i> Services*!/*!/*/}
            {/*      /!*        /!*  </Link>*!/*!/*/}
            {/*      /!*        /!*</li>*!/*!/*/}
            {/*      /!*        /!*<li>*!/*!/*/}
            {/*      /!*        /!*  <Link to="/contact">*!/*!/*/}
            {/*      /!*        /!*    <i className="flaticon-next"></i> Contact Us*!/*!/*/}
            {/*      /!*        /!*  </Link>*!/*!/*/}
            {/*      /!*        /!*</li>*!/*!/*/}
            {/*      /!*      </ul>*!/*/}
            {/*      /!*    </div>*!/*/}
            {/*      /!*  </div>*!/*/}
            {/*      /!*</div>*!/*/}

            {/*      /!*<div className="col-lg-3 col-sm-6">*!/*/}
            {/*      /!*  <div className="footer-widjet">*!/*/}
            {/*      /!*    <h3>Useful Links</h3>*!/*/}
            {/*      /!*    <div className="link-list">*!/*/}
            {/*      /!*      <ul>*!/*/}
            {/*      /!*        <li>*!/*/}
            {/*      /!*          <Link to="#pricing">*!/*/}
            {/*      /!*            <i className="flaticon-next"></i> Pricing*!/*/}
            {/*      /!*          </Link>*!/*/}
            {/*      /!*        </li>*!/*/}
            {/*      /!*        <li>*!/*/}
            {/*      /!*          <Link to="#">*!/*/}
            {/*      /!*            <i className="flaticon-next"></i> App Download*!/*/}
            {/*      /!*          </Link>*!/*/}
            {/*      /!*        </li>*!/*/}
            {/*      /!*        <li>*!/*/}
            {/*      /!*          <Link to="#">*!/*/}
            {/*      /!*            <i className="flaticon-next"></i> How It Works*!/*/}
            {/*      /!*          </Link>*!/*/}
            {/*      /!*        </li>*!/*/}
            {/*      /!*        <li>*!/*/}
            {/*      /!*          <Link to="/terms-conditions">*!/*/}
            {/*      /!*            <i className="flaticon-next"></i> Terms & Conditions*!/*/}
            {/*      /!*          </Link>*!/*/}
            {/*      /!*        </li>*!/*/}
            {/*      /!*        <li>*!/*/}
            {/*      /!*          <Link to="/privacy-policy">*!/*/}
            {/*      /!*            <i className="flaticon-next"></i> Privacy Policy*!/*/}
            {/*      /!*          </Link>*!/*/}
            {/*      /!*        </li>*!/*/}
            {/*      /!*      </ul>*!/*/}
            {/*      /!*    </div>*!/*/}
            {/*      /!*  </div>*!/*/}
            {/*      /!*</div>*!/*/}

            {/*      /!*<div className="col-lg-2 col-sm-6">*!/*/}
            {/*      /!*  <div className="download-link">*!/*/}
            {/*      /!*    <h3>Download Link</h3>*!/*/}
            {/*      /!*    <div className="fotter-img">*!/*/}
            {/*      /!*      <a*!/*/}
            {/*      /!*        href="https://www.apple.com/app-store/"*!/*/}
            {/*      /!*        target="_blank"*!/*/}
            {/*      /!*        rel="noreferrer"*!/*/}
            {/*      /!*      >*!/*/}
            {/*      /!*        <img src={footer1} alt="Image" />*!/*/}
            {/*      /!*      </a>*!/*/}
            {/*      /!*      <a href="https://play.google.com/store/apps?hl=en&gl=GB" target="_blank" rel="noreferrer">*!/*/}
            {/*      /!*        <img src={footer2} alt="Image" />*!/*/}
            {/*      /!*      </a>*!/*/}
            {/*      /!*    </div>*!/*/}
            {/*      /!*  </div>*!/*/}
            {/*      /!*</div>*!/*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="copy-right-area bg-dark">
                <div className="container " style={{paddingBottom: "100px"}}>
                    <p className={"text-white"}>
                        © Venti The Dev.<br/>
                        Visit Counter:
                        <strong id="visit"> {visitor}</strong>
                    </p>
                </div>
            </div>
        </>
    )
}

export default Footer
