import * as React from "react"
import * as L from "leaflet"
import "leaflet/dist/leaflet.css"
// import newsletterImg1 from "../../images/newsletter-img.png"
import shape6 from "../../images/shape/baphomet.png"
import shape7 from "../../images/shape/poring-1.png"
import {MapContainer, Marker, Polyline, TileLayer, useMapEvents} from "react-leaflet"
import iconUrl from "../../images/shape/marker-flag.png";
import iconStart from "../../images/shape/marker-people.png";
import iconNormal from "../../images/shape/marker-icon.png";
import {useEffect, useState} from "react"

import Leaflet from "leaflet"


const Graph = require("node-dijkstra")
const midnode = {
    A: [-37.5, 39.75],
    B: [-36.5, 105.75],
    C: [-37.5, 172.25],
    D: [-40, 221.25],
    E: [-103.5, 40.75],
    F: [-104, 108.75],
    G: [-108, 175.25],
    H: [-105, 244.75],
    I: [-176, 42.5],
    J: [-167.5, 110.75],
    K: [-173, 174.25],
    L: [-177, 242.75],
    M: [-242, 53.75],
    N: [-240, 110.75],
    O: [-236, 171.25],
    P: [-242, 251.75],
}
const nodeLocation = {
    A: {H: [-13, 46.75], K: [-40, 12.75]},
    B: {O: [-10.5, 101.75], C: [-60.5, 112.25]},
    C: {J: [-11.5, 174.75], H: [-40, 202.25]},
    D: {K: [-16.5, 269.25], I: [-55, 270.25]},
    E: {H: [-126, 43.75], J: [-85, 34.25]},
    F: {D: [-129.5, 110.75], L: [-105.5, 81.25], P: [-114.5, 130.25], M: [-82, 108.25]},
    G: {I: [-117.5, 199.75], N: [-117, 150.75]},
    H: {A: [-81.5, 254.75], O: [-105, 222.75]},
    I: {G: [-157, 41.75], O: [-197.5, 42.25], P: [-177, 17.25]},
    J: {B: [-151, 121.25], A: [-184.5, 128.25], M: [-198, 89.25]},
    K: {B: [-200, 170.25], D: [-147.5, 181.75], F: [-181, 151.25], P: [-179.5, 199.25]},
    L: {F: [-151.5, 235.75], G: [-200.5, 242.75]},
    M: {G: [-266, 56.25], N: [-218, 47.75]},
    N: {F: [-229, 85.75], L: [-248, 132.75]},
    O: {E: [-249, 150.25], A: [-218, 169.75]},
    P: {D: [-220.5, 262.75], J: [-264.5, 238.25]}
}
const Contact = () => {


    const [gameX, setGameX] = useState();
    const [gameY, setGameY] = useState();
    const [startingPoint, setStartingPoint] = useState()
    const [endingPoint, setEndingPoint] = useState()
    const [testMarker, setTestMarker] = useState()
    const [markers, setMarker] = useState()
    const [liner, setLiner] = useState()
    const route = new Graph()

    route.addNode("A", {H: 1, K: 1}) // 1
    route.addNode("B", {O: 1, C: 1})
    route.addNode("C", {J: 1, H: 1})
    route.addNode("D", {K: 1, I: 1})
    route.addNode("E", {H: 1, J: 1})
    route.addNode("F", {D: 1, L: 1, P: 1, M: 1})
    route.addNode("G", {I: 1, N: 1})
    route.addNode("H", {A: 1, O: 1})
    route.addNode("I", {G: 1, O: 1, P: 1})
    route.addNode("J", {B: 1, A: 1, M: 1})
    route.addNode("K", {B: 1, D: 1, F: 1, P: 1})
    route.addNode("L", {F: 1, G: 1})
    route.addNode("M", {G: 1, N: 1})
    route.addNode("N", {F: 1, L: 1})
    route.addNode("O", {E: 1, A: 1})
    route.addNode("P", {D: 1, J: 1})

    const MapEvents = () => {
        useMapEvents({
            click(e) {
                // setState your coords here
                // coords exist in "e.latlng.lat" and "e.latlng.lng"
                console.log(e.latlng.lat)
                console.log(e.latlng.lng)
            }
        })
        return false
    }

    function setToX(e) {
        setGameX(e.target.value)
    }

    function setToY(e) {
        setGameY(e.target.value)
    }
    function decodeIslandNumber(x, y) {
        // Define the island ranges on the grid
        const islandRanges = [
            {minX: 25, minY: 123, maxX: 57, maxY: 155, islandNumber: 'A'},
            {minX: 25, minY: 90, maxX: 57, maxY: 123, islandNumber: 'E'},
            {minX: 25, minY: 57, maxX: 57, maxY: 90, islandNumber: 'I'},
            {minX: 25, minY: 25, maxX: 57, maxY: 57, islandNumber: 'M'},

            {minX: 57, minY: 123, maxX: 90, maxY: 155, islandNumber: 'B'},
            {minX: 57, minY: 90, maxX: 90, maxY: 123, islandNumber: 'F'},
            {minX: 57, minY: 57, maxX: 90, maxY: 90, islandNumber: 'J'},
            {minX: 57, minY: 25, maxX: 90, maxY: 57, islandNumber: 'N'},

            {minX: 90, minY: 123, maxX: 125, maxY: 155, islandNumber: 'C'},
            {minX: 90, minY: 90, maxX: 125, maxY: 123, islandNumber: 'G'},
            {minX: 90, minY: 57, maxX: 125, maxY: 90, islandNumber: 'K'},
            {minX: 90, minY: 25, maxX: 125, maxY: 57, islandNumber: 'O'},

            {minX: 125, minY: 123, maxX: 160, maxY: 155, islandNumber: 'D'},
            {minX: 125, minY: 90, maxX: 160, maxY: 123, islandNumber: 'H'},
            {minX: 125, minY: 57, maxX: 160, maxY: 90, islandNumber: 'L'},
            {minX: 125, minY: 25, maxX: 160, maxY: 57, islandNumber: 'P'},


        ];

        // Iterate through the island ranges and find which island the coordinates fall within
        for (const island of islandRanges) {
            if (parseInt(x) >= island.minX && parseInt(x) <= island.maxX && parseInt(y) >= island.minY && parseInt(y) <= island.maxY) {
                // console.log("ISLAND: ",island.islandNumber);
                return island.islandNumber;
            }
        }
    }
                function calculateFromGameToMap(x, y) {
        const map_x = 0.6106834 * x - 180.7315;
        const map_y = -0.1669049 * y + 204.4898;
        console.log(map_x, map_y);
        const startIcon = new Leaflet.Icon({
            iconUrl: iconStart,
            iconAnchor: [15, 55],
            iconSize: [38, 55]
        });
        if (map_x && map_y)
            setTestMarker(<Marker icon={startIcon} position={[map_x, map_y]} markerColor={"green"}
                                  iconColor={"green"}> </Marker>)
    }

    function generateNumberOptions(start, end) {
        const options = []
        options.push(<option disabled selected={true} >Select Island Number</option>)
        options.push(<option value="N" key={"N"}>13 (North Gate)</option>)
        options.push(<option value="C" key={"C"}>2 (3F)</option>)
        for (let i = start; i <= end; i++) {
            if (i === 14 || i === 3)
                continue
            options.push(<option  key={String.fromCharCode(i + 64)} value={String.fromCharCode(i + 64)}>{i - 1}</option>)
        }
        return options
    }

    function plotTheMap(startingPoint, endingPoint) {

        console.log(startingPoint, endingPoint)
        let pos = []
        let marker = [];

        setMarker(null)
        // console.log(pos);
        setLiner(null);

        if (startingPoint === endingPoint || (!startingPoint) || (!endingPoint)) {
            return;
        }

        const result = route.path(startingPoint, endingPoint) // => [ 'A', 'B', 'C', 'D' ]
        if (startingPoint) {
            const startIcon = new Leaflet.Icon({
                iconUrl: iconStart,
                iconAnchor: [15, 55],
                iconSize: [38, 55]
            });
            marker.push(<Marker icon={startIcon} position={midnode[startingPoint]} markerColor={"green"}
                                iconColor={"green"}> </Marker>)
            pos.push(midnode[startingPoint]);
        }
        if (!result) {
            console.log("No result!")
            return;
        }


        // console.log("node", midnode[startingPoint]);


        for (let i = 0; i < result.length; i++) {
            if (i + 1 < result.length) {
                const normalIcon = new Leaflet.Icon({
                    iconUrl: iconNormal,
                    iconAnchor: [15, 55],
                    iconSize: [38, 55]
                });
                // marker.push(icon)
                marker.push(<Marker icon={normalIcon}
                                    position={nodeLocation[result[i]][result[i + 1]]} markerColor={"blue"}
                                    iconColor={"red"}>

                </Marker>)
                pos.push(nodeLocation[result[i]][result[i + 1]])

            }


            result[i] = result[i].charCodeAt(0) - 64
        }


        // console.log(midnode[String.fromCharCode(result[result.length-1]+64)])

        const newicon = new Leaflet.Icon({
            iconUrl,
            iconAnchor: [15, 55],
            iconSize: [38, 55]
        });
        marker.push(<Marker icon={newicon}
                            position={midnode[endingPoint]} markerColor={"green"} iconColor={"green"}>
        </Marker>)
        pos.push(midnode[String.fromCharCode(result[result.length - 1] + 64)]);


        const finalLiner = (<Polyline positions={pos} color="green"/>)
        // console.log(result)
        setMarker(marker)
        // console.log(pos);
        setLiner(finalLiner);

        //Traversal through node

    }

    function sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    function resetPath() {
        const e = {target: {value: undefined}};
        setStartingPointer(e);
        setEndingPointer(e);
    }
    const [lastEndingPoint,setLastEndingPoint] = useState();
    useEffect(() => {
        setMarker(null)
        // console.log(pos);
        setLiner(null);
        if(gameX && gameY)
        {
            const newEndingPoint = decodeIslandNumber(gameX, gameY);
            setLastEndingPoint(newEndingPoint);
            sleep(10).then(r => plotTheMap(startingPoint, newEndingPoint));
            setEndingPoint(newEndingPoint);

        }
        else{
            if(lastEndingPoint === endingPoint || !endingPoint)
                return;
            sleep(10).then(r => plotTheMap(startingPoint, endingPoint));
            setLastEndingPoint(endingPoint);
            let el = document.getElementById("x");
            el.value = "";
            doEvent( el, 'input' );
            el = document.getElementById("y");
            el.value = "";
            doEvent( el, 'input' );
        }


        // calculateFromGameToMap(gameX, gameY);
    }, [startingPoint, endingPoint, gameX, gameY])

    function doEvent( obj, event ) {
        /* Created by David@Refoua.me */
        let eventer = new Event( event, {target: obj, bubbles: true} );
        return obj ? obj.dispatchEvent(eventer) : false;
    }

    const setStartingPointer = (e) => {
        setStartingPoint(e.target.value)
    }
    const setEndingPointer = (e) => {
        setEndingPoint(e.target.value);
        setGameX(null);
        setGameY(null);
    }
    return (
        <>
            <div id="contact" className="contact-and-subscribe-area pt-100 pb-70 bg-dark">
                <div className="container">
                    <div className="sass-section-title">
                        {/*<span>Be The First To Know</span>*/}
                        {/*<h2>Grow Your Brand Presence on Social Media</h2>*/}
                    </div>

                    <div className="row">
                        <div
                            className="col-lg-6"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                            data-aos-delay="300"
                            data-aos-once="true"
                        >
                            <div className="contact-widget" style={{backgroundColor: "#3a3a3a"}}>
                                <div className="widget-content">
                                    <div className="contact-title">
                                        <span>Ragnarok Origin</span>
                                        <h3 className={"text-white"}>Labyrinth Forest 1F Path Finder</h3>
                                    </div>

                                    <div className="contact-form">
                                        <form id="contactForm" onSubmit={e => e.preventDefault()}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group ">
                                                        <label className={"text-white"}>
                                                            Start Number:
                                                        </label>
                                                        <br/>
                                                        <select key={"starting"} name="starting" id="starting" className="form-control"
                                                                onChange={setStartingPointer}>
                                                            {/*<option value="13" defaultChecked={true}>13 (North Gate)</option>*/}
                                                            {generateNumberOptions(1, 16)}

                                                        </select>

                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className={"text-white"}>
                                                            End Number:
                                                        </label>
                                                        <br/>
                                                        <select key={"ending"}  value={endingPoint} name="target" id="target" className="form-control"
                                                                onChange={setEndingPointer}>
                                                            {/*<option value="13" defaultChecked={true}>13 (Default)</option>*/}
                                                            {generateNumberOptions(1, 16)}

                                                        </select>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">


                                                        <div className={"row"}>
                                                            <div className={"col-md-6 form-group"}>
                                                                <label className={"text-white "}>
                                                                    Or X
                                                                </label>
                                                                <input onInput={setToX} name={"x"} id={"x"} value={gameX}
                                                                       type={"number"} className="form-control"/>
                                                            </div>

                                                            <div className={"col-md-6 form-group"}>
                                                                <label className={"text-white "}>
                                                                    , Y
                                                                </label>
                                                                <input onInput={setToY} name={"y"} id={"y"} value={gameY}
                                                                       type={"number"} className="form-control"/>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                                {/*<div className="col-12">*/}
                                                {/*  <div className="form-group">*/}
                                                {/*    <textarea*/}
                                                {/*      name="message"*/}
                                                {/*      className="form-control"*/}
                                                {/*      id="message"*/}
                                                {/*      cols="30"*/}
                                                {/*      rows="6"*/}
                                                {/*      placeholder="Message"*/}
                                                {/*      required*/}
                                                {/*    ></textarea>*/}
                                                {/*  </div>*/}
                                                {/*</div>*/}

                                                <div className="col-lg-12 col-md-12">
                                                    <button
                                                        type="submit"
                                                        onClick={resetPath}
                                                        className="sass-default-btn btn"
                                                    >
                                                        <span>Clear Map</span>
                                                    </button>
                                                </div>

                                                <div className="col-lg-12 col-md-12 ">
                                                    <br/>
                                                    <br/>
                                                    <h5 className={"text-white"}>Last bug fixed</h5>
                                                    <p className={"text-white"}>- Fix location market from Island #0 to
                                                        Island #10<br/>
                                                        - Improve performance / Add Target input Game (X,Y)<br/>
                                                        - Updated on 1 June 2023 02:47AM<br/>
                                                    </p>

                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="col-lg-6"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                            data-aos-delay="600"
                            data-aos-once="true"
                        >
                            <div className="contact-widget" style={{padding: "0", margin: "0"}}>
                                <div className="widget-content">
                                    {/*<div className="contact-title">*/}
                                    {/*  <span>Subscribe</span>*/}
                                    {/*  <h3>Our Newsletter</h3>*/}
                                    {/*</div>*/}

                                    {/*<div className="newsletter-img">*/}
                                    {/*  <img src={newsletterImg1} alt="Image" />*/}
                                    {/*</div>*/}

                                    {/*<div className="contact-form">*/}

                                    <MapContainer
                                        className={"full-height-map"}
                                        style={{height: "565px"}}
                                        center={[-140, 140]} draggable={false} zoom={1} scrollWheelZoom={false}
                                        minZoom={1} maxZoom={1}
                                        maxBounds={[[0, 0], [-280, 280]]}
                                        crs={L.CRS.Simple}
                                    >

                                        <TileLayer noWrap={true} tileSize={140}
                                                   url="https://baphomap.vecia.app/static/labyrinth_forest/{z}/{y}_{x}.png"
                                                   attribution="&copy; Venti The Dev. Labyrinth Forest</a> "
                                        />
                                        <MapEvents/>
                                        {/*<Marker position={[-140, 140]} markerColor={"blue"} iconColor={"red"}>*/}
                                        {/*  <Popup>*/}
                                        {/*    A pretty CSS3 popup. <br /> Easily customizable.*/}
                                        {/*  </Popup>*/}
                                        {/*</Marker>*/}
                                        {markers}
                                        {liner}
                                        {testMarker}
                                        {/*<Marker position={[225,-225]} markerColor={'blue'}  iconColor={'blue'} >*/}
                                        {/*  <Popup>*/}
                                        {/*    A pretty CSS3 popup. <br /> Easily customizable.*/}
                                        {/*  </Popup>*/}
                                        {/*</Marker>*/}
                                        {/*<Marker position={[550,225]} markerColor={'blue'}  iconColor={'green'} >*/}
                                        {/*  <Popup>*/}
                                        {/*    A pretty CSS3 popup. <br /> Easily customizable.*/}
                                        {/*  </Popup>*/}
                                        {/*</Marker>*/}
                                    </MapContainer>

                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape">
                    <img
                        src={shape6}
                        className="contact-shape-1"
                        alt="Image"
                    />
                    <img
                        src={shape7}
                        className="contact-shape-2"
                        alt="Image"
                    />
                </div>
            </div>
        </>
    )
}

export default Contact
