import * as React from "react"
import {Link} from 'react-router-dom';

import bannerMainImg from "../../images/banner/banner-img-10.png"
import authorImg from "../../images/reviews/reviews-16.png"
import bannerBgImg from "../../images/banner/banner-bg-6.jpg"
import Web3 from 'web3';
import {CONTACT_ABI, CONTACT_ADDRESS} from '../../abis/config';
import {useEffect, useState} from "react";
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import Form from 'react-bootstrap/Form';
import {
    SecretsManagerClient,
    GetSecretValueCommand,
} from "@aws-sdk/client-secrets-manager";
import jwt_decode from "jwt-decode";
import AWS from 'aws-sdk';
import {Button} from "bootstrap";

const keccak256 = require('keccak256');
const jose = require('node-jose');

function generateKeccak256(data) {
    return keccak256(data).toString('base64')
}

function getBufferForKey(salt, keyvalue) {
    // console.log("Keyvalue:");
    // console.log(keyvalue);
    let kdfParams = {
        salt: salt,
        iterations: 8192,
        length: 64
    };
    return jose.JWA.derive("PBKDF2-SHA-256", Buffer.from(keyvalue, 'utf-8'), kdfParams);
    // return jose.JWA.derive("PBKDF2-SHA-256", Buffer.from(keyvalue, 'utf-8'), kdfParams);
}

function retrieveCryptoKey(header, key) {
    return getBufferForKey(header.salt, key).then(keyBuffer => jose.JWK.asKey({kty: 'oct', k: keyBuffer, use: "enc"}));
}

async function Decryptlayer1AES256(encoded, key) {
    // if(key === "")
    // {
    //     key = process.env.LV1_SECURE_KEY
    // }
    key = generateKeccak256(key)
    const contentAlg = "PBES2-HS512+A256KW"
    const salt = encoded.substr(encoded.length - 13);
    const newEncoded = encoded.substr(0, encoded.length - 12);
    // console.log(newEncoded);
    let header = {
        alg: contentAlg,
        enc: "A256GCM",
        p2c: 8192,
        p2s: salt
    }

    let q = retrieveCryptoKey(header, key)
        .then(async decryptionKey => {
            let result = await jose.JWE.createDecrypt(decryptionKey).decrypt(newEncoded);
            // console.log("result")
            // console.log(result)
            let td = new TextDecoder('utf-8'),
                stringPayload = td.decode(result.payload),
                parsedPayload = null;
            try {
                parsedPayload = JSON.parse(stringPayload);
            } catch (e) {
                // not JSON. It's a JWE, not JWT
            }

            return parsedPayload
        });

    return JSON.stringify(await q);
}

function MainBanner({chainid, keycard, addr, data}) {
    const [secret, setSecret] = useState();
    const [contactList, setContactList] = useState();
    const [userData, setUserData] = useState({
        "error": "Failed to decrypt the content",
        "Key Mismatched": "Cannot Decrypt contents"
    });
    const [nftName, setNftName] = useState();
    const [balance, setBalance] = useState();
    const [blockchain_dat, setBlockchain_dat] = useState();
    const [dataIPFS, setData] = useState();
    const [fullSync, setFullSync] = useState(false);
    const [passcode, setPasscode] = useState();
    const [response, setResponse] = useState();
    const [enc, setEnc] = useState();
    const [keymatch, setKeyMatch] = useState(false);
    useEffect(() => {

        if (!fullSync) {
            (async () => {
                if (!blockchain_dat || !response || !dataIPFS) {
                    const web3 = new Web3(Web3.givenProvider || 'https://data-seed-prebsc-1-s1.binance.org:8545/');
                    const contactList = new web3.eth.Contract(CONTACT_ABI, addr);
                    setContactList(contactList);
                    const blockchain_dat = await contactList.methods.baseURI().call();
                    // console.log(blockchain_dat);
                    setBlockchain_dat(blockchain_dat);
                    const nameNFT = await contactList.methods.name().call();
                    setNftName(nameNFT);
                    setBalance(await contactList.methods.totalSupply().call());

                    await fetch(blockchain_dat).then(resp => {
                        resp.json().then(result => {
                            setResponse(result);
                            // console.log(result);
                            setData(result);
                            setEnc(result['enc']);
                        })
                    })

                }

                let json = null;

                if (enc) {
                    // console.log("Loaded",res);

                    try {
                        json = response;
                        // console.log(json);
                        const ipfs = enc;
                        const nfc = data;
                        let ipfs_c = 0;
                        let nfc_c = 0;
                        let final = "";

                        //nfc first
                        let i = 0;
                        while (i < (ipfs + nfc).length) {
                            if ((ipfs + nfc).length % 2 === 0) {
                                if (i % 2 === 0) {
                                    final += nfc[nfc_c];
                                    nfc_c += 1;
                                } else {
                                    final += ipfs[ipfs_c];
                                    ipfs_c += 1;
                                }
                            } else {
                                if (i % 2 !== 0) {
                                    final += nfc[nfc_c];
                                    nfc_c += 1;
                                } else {
                                    final += ipfs[ipfs_c];
                                    ipfs_c += 1;
                                }
                            }
                            i += 1;
                        }
                        // console.log(final);
                        if(passcode)
                        {
                            try {
                                const dec = await Decryptlayer1AES256(final, passcode);
                                // console.log(dec);
                                setUserData(dec);
                                setKeyMatch(keycard === JSON.parse(dec)['key']);
                            } catch (e) {
                                setUserData({
                                    "error": "Failed to decrypt the content",
                                    "Key Mismatched": "Cannot Decrypt contents"
                                })
                                console.log({
                                    "error": "Failed to decrypt the content",
                                    "Key Mismatched": "Cannot Decrypt contents",
                                    "events": "The more you faild, less ram you get"
                                });
                            }
                        }


                        // responsesec.SecretString;
                    } catch (error) {
                        // For a list of exceptions thrown, see
                        // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
                        throw error;
                    }

                }


            })();
        }


    }, [blockchain_dat, passcode, dataIPFS]);

    function updatePasskey(event) {
        // console.log(event.target.value);
        if (event.target.value && event.target.value !== "")
            setPasscode(event.target.value);
    }
    function getIPFS(dataIPFS)
    {
        let gatewayurl = dataIPFS['properties']['image']['description'].replace('http://','https://');
        gatewayurl =  gatewayurl.replace("ipfs.io/ipfs/",'');
        gatewayurl = gatewayurl+= ".ipfs.nftstorage.link";
        return gatewayurl;
    }
    return (
        <>
            <div
                id="home"
                className="nft-banner-area"
                style={{backgroundImage: `url(${bannerBgImg})`}}
            >
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6" style={{paddingBottom: "40px"}}>
                            <div
                                className="nft-banner-image"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                                data-aos-delay="400"
                            >
                                {dataIPFS && dataIPFS['properties'] && dataIPFS['properties']['image'] && dataIPFS['properties']['image']['description'] ? <>
                                    <img src={getIPFS(dataIPFS)} alt="Image"/>
                                    {/*<div className="current-bid">*/}
                                    {/*    <div className="profile">*/}
                                    {/*        <img*/}
                                    {/*            src={dataIPFS['properties']['image']['description']}*/}
                                    {/*            alt="Image"*/}
                                    {/*        />*/}
                                    {/*        <h3>{userData['name']}</h3>*/}
                                    {/*    </div>*/}

                                    {/*    <ul className="bid-list d-flex justify-content-between">*/}
                                    {/*        <li>*/}
                                    {/*            <p>NFC Key</p>*/}
                                    {/*            <span>{userData['key']}</span>*/}
                                    {/*        </li>*/}

                                    {/*    </ul>*/}

                                    {/*    <Link to="#">*/}
                                    {/*        Place a Bid <i className="flaticon-right-arrow-2"></i>*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                </> : ""}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="nft-banner-content"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                                data-aos-delay="200"
                            >
                                <h1>{nftName}</h1>
                                <span>NFT Address:{addr}</span><br/>
                                <span>Total Supply: {balance} NFT(s)!</span>


                                <p>
                                    Base of NFT URL: {blockchain_dat}
                                </p>
                                {dataIPFS && dataIPFS['properties'] && dataIPFS['properties']['image'] && dataIPFS['properties']['image']['description'] ?
                                    <a href={dataIPFS['properties']['image']['description'].replace('http://','https://')}
                                       className="nft-default-btn style2 btn">
                                        Image on IPFS <i className="flaticon-right-arrow-2"></i>
                                    </a>
                                    : ""}
                                <a href={blockchain_dat} className="nft-default-btn mr-20 btn">
                                    Open on IPFS <i className="flaticon-right-arrow-2"></i>
                                </a>

                                <a href={"https://testnet.bscscan.com/address/" + addr}
                                   className="nft-default-btn style2 btn">
                                    Smart Contract <i className="flaticon-right-arrow-2"></i>
                                </a>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
            <div className="create-and-sell-area bg-color-0a0a0a pt-100 pb-70">
                <div className="container">
                    <div className="top-sellers-top-content">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="nft-section-title style2">
                                    <h2>Data from Decrypted </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">


                                <JSONPretty id="json-pretty-2" data={userData}/>

                            </div>
                            <div className="col-lg-6 col-md-6 text-center justify-content-center">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label style={{color: "white"}}>Fill the Password for decrypted &
                                        identifier</Form.Label>
                                    <Form.Control type="password" aria-required={true} onChange={updatePasskey}/>
                                    <br/>
                                    <h6 style={{color: keymatch ? "limegreen" : "red"}}>{keymatch === true ? "Provided key: " + keycard + " match!" : "Provided key not matched!"}</h6>

                                </Form.Group>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div id="tranding" className="nft-tranding-area pt-100 pb-70">
                <div className="container">
                    <div className="trnding-top-content">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="nft-section-title">
                                    <h2>NFT Metadata on IPFS</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12">

                                <JSONPretty id="json-pretty" data={dataIPFS}/>

                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </>
    )
}

export default MainBanner
